import './App.css';
import UnderConstructionPage from './Pages/UnderConstruction';

function App() {
  return (
    <div className="App">
      <UnderConstructionPage/>
      {/* <HomePage/> */}
    </div>
  );
}

export default App;
